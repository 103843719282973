/* General styles */
body,
html {
  height: 100%;
  background-color: #f5f5f5;
}

.App,
.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f5f5f5;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  height: max-content;
}

.activate-button,
.modal button,
.level-button {
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #3f51b5;
  border-radius: 4px;
}

.activate-button {
  padding: 10px 20px;
  margin-bottom: 20px;
}

.modal button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.3s;
  margin: 0.5rem;
}

.modal button:hover {
  background-color: #0d47a1;
}

.modal button:active {
  background-color: #004c8c;
}

.level-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Spellcaster styles */
.spellcaster-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  /* overflow-y: auto; */
}

.spellcaster {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  padding: 1.5rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  width: max-content;
  max-width: 100%;
  height: max-content;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}

.modal-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.modal-content label {
  display: block;
  margin-top: 1rem;
}

.modal-content input,
.modal-content select {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .spellcaster-container {
    margin-bottom: 120px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Rest of the styles */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.spellcaster h2, .modal-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.spell-slots h3, .slot h4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}

.spellcaster select {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  margin-bottom: 1.5rem;
  outline: none;
}

.spellcaster select:focus {
  border: 1px solid #1e88e5;
}

.slot {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.slot h4 {
  margin-right: 1rem;
}

.bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-right: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bubble-filled {
  background-color: #1e88e5;
}

.focused-slots-options {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 10px;
}

.focused-slots-options div {
  display: flex;
  align-items: center;
}

.focused-slots-options input[type="radio"] {
  margin-right: 5px;
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

.focused-slots-options label {
  font-size: 16px;
  line-height: 16px;
  vertical-align: middle;
}
.focused-slots-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.focused-slots-options input[type="radio"] {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.focused-slots-options label {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  max-height: 90vh; /* Adjust this value to your preference */
  overflow-y: auto; /* Enables scrolling when the content overflows */
  background: white; /* Makes the modal opaque */
}


.mdc-dialog__surface {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.mdc-text-field {
  position: relative;
  width: 100%;
  height: 56px;
}

.mdc-text-field__input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.42);
  width: 100%;
  padding: 20px 0;
}

.mdc-floating-label {
  position: absolute;
  top: 24px;
  left: 0;
  transform-origin: top left;
  transition: all 0.15s ease-out;
  color: rgba(0,0,0,0.6);
}

.mdc-text-field__input:focus {
  outline: none;
  border-bottom: 2px solid #6200ee;
}

.mdc-text-field__input:focus ~ .mdc-floating-label {
  transform: scale(0.75) translateY(-10px);
  color: #6200ee;
}

.mdc-button {
  color: white;
  padding: 0 16px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  outline: none;
  border: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 36px;
  border-radius: 4px;
  margin: 4px;
}

.mdc-button--raised {
  background-color: #1e88e5;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.mdc-typography--headline5 {
  margin-bottom: 16px;
}

.mdc-floating-label--float-above {
  transform: translateY(-50%) scale(.75);
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}
.spell-used {
  background-color: rgb(128, 0, 0);
  color: rgb(0, 0, 0);
  opacity: 3.6;
}

.mdc-button:hover {
  cursor: pointer;
}
.add-spell-button{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  outline: none;
  border: none;
  height: 30px;
  width: 30px;
}
.add-spell-button:hover{
  cursor: pointer;
  background-color: rgb(226, 226, 226);
  border-radius: 50%;
}